
$fa-font-path: '/fonts/fontawesome-free/webfonts';
$nunito-font-path: '/fonts/NunitoSans';

@import "fonts";
@import "light-variables";
@import "base-variables";
@import "bulma-import";
@import "fontawesome-import";
@import "base";
@import "light-style";
@import "schulfee";
